import { Component, Input, OnInit } from "@angular/core";
import {
  ExportDataExperienceResponse,
  ExportDataForExperienceResponse,
  ExportDataForExperienceService,
} from "@services/dws/export-data-for-experience";
import { observe } from "@web/util/loading/loading";
import { access } from "@util/route/access";
import { ActivatedRoute } from "@angular/router";
import { Login } from "@services/accounts.service";
import { tr } from "@util/tr";
import { CompanyService } from "@services/company.service";
import { Summary } from "@angular/compiler";
import { Room } from "@interfaces/dws/room";
import { Employee } from "@interfaces/dws/employee";
import { VwReservation } from "@interfaces/dws/reservation";
import { locale } from "moment";
import * as moment from "moment";
import { toast } from "@web/util/toast";

@Component({
  selector: "reservation-pdf-exporter",
  templateUrl: "./reservation-pdf-exporter.component.html",
  styleUrls: ["./reservation-pdf-exporter.component.scss"],
})
export class ReservationPdfExporterComponent implements OnInit {

  login: Login;
  company: { id: string; name: string; avatar: string };
  exportingPdf = false;
  dataPdf: ExportDataExperienceResponse | undefined = undefined;
  cssFilePath: string = "/assets/styles/pdfExportStyle.css";

  startingDate : Date|undefined;
  endingDate : Date|undefined;
  startingTime : string|undefined;
  endingTime : string|undefined;


  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];

  language: any = locale();

  constructor(
    private exportDataForExperienceService: ExportDataForExperienceService,
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {
    this.login = access(route).data("login");
    this.company = access(route).data("companyAlt");
  }

  ngOnInit() {}

  public downloadAsPDF(
    startDate: Date,
    endDate: Date,
    experienceId: string | undefined,
    wineryId: string
  ): void {
    var that = this;
    function doThing() {
      observe(
        that.exportDataForExperienceService.fetch({
          startDate: startDate,
          endDate: endDate,
          experienceId: experienceId,
          wineryId: wineryId
        })
      ).subscribe((e) => {
        that.dataPdf = e;
        if (that.dataPdf.results.length === 0) {
          toast(tr(
            "There are no reservations in the selected period, change dates and try again.")
          );
          return;
        }
        that.exportingPdf = true;

        const interval = setInterval(() => {
          const element = <HTMLElement>(
            document.getElementById("pdfExportExperiences")
          );

          if (element) {
            if (that.exportingPdf) {
              that.print(element);
              that.exportingPdf = false;
            }
          }

          clearInterval(interval);
          return;
        }, 300);
      });
      that.startingDate = startDate;
      that.endingDate = endDate;

    }

    if (!this.company) {
      observe(this.companyService.get(wineryId), false).subscribe((data) => {
        this.company = data;
        doThing();
      });
    } else {
      doThing();
    }

        
  }

  public print(element: HTMLElement): void {
    function getElementTag(tag: keyof HTMLElementTagNameMap): string {
      const html: string[] = [];
      const elements = document.getElementsByTagName(tag);
      for (let index = 0; index < elements.length; index++) {
        html.push(elements[index].outerHTML);
      }
      return html.join("\r\n");
    }

    let printContents,
      styles = "",
      links = "";
    const baseTag = getElementTag("base");

    printContents = element.innerHTML;
    const popupWin = window.open(
      "availability",
      "_blank",
      "top=0,left=0,height=auto,width=auto"
    );
    popupWin?.document.open();
    popupWin?.document.write(`
        <html>
          <head>
            <title>${tr("Reservations")}</title>
            ${baseTag}         
            <link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet">
            <link href="/assets/styles/pdfExportStyle.css" rel="stylesheet">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

          </head>
          <body>
            ${printContents}
            <script defer>
              funcconsttion triggerPrint(event) {
                var windowUrl = 'Availability';
                window.removeEventListener('load', triggerPrint, false);
                setTimeout(function() {
                  //closeWindow(window.print());
                }, ${0});
              }
              function closeWindow(){
                  window.close();
              }
              window.addEventListener('load', triggerPrint, false);
            </script>
          </body>
        </html>`);
    popupWin?.document.close();
  }

  public labelTotal(x: ExportDataForExperienceResponse) {
    const labels = x.summary.labelsTotal;
    // @ts-ignore
    return Object.keys(labels)
      .map((e) => +labels[e])
      .reduce((p, a) => p + a, 0);
  }

  public labelsAndExtrasJoinSummaryToObjArr(
    x: ExportDataForExperienceResponse
  ) {
    const labels = x.summary.labelsTotal;
    const extras = x.summary.extrasTotal;
    const l = Object.keys(labels).map((e) => {
      return { labelName: e, labelValue: labels[e] };
    });
    const e = Object.keys(extras).map((e) => {
      return { labelName: e, labelValue: extras[e] };
    });

    return [...l, ...e];
  }

  public getLabels(x: ExportDataForExperienceResponse) {
    const labels = x.summary.labelsTotal;
    const l = Object.keys(labels).map((e) => {
      return { labelName: e, labelValue: labels[e] };
    });
    return [...l];
  }

  public getExtras(x: ExportDataForExperienceResponse) {
    const extras = x.summary.extrasTotal;
    const e = Object.keys(extras).map((e) => {
      return { labelName: e, labelValue: extras[e] };
    });
    return [...e];
  }

  public getOtherLabelsValue(
    reservation: any,
    x: ExportDataForExperienceResponse
  ) {
    const otherlabels = this.getLabels(x).slice(3);
    return otherlabels.reduce((sum,l1)=>{return sum + (this.getLabelOrZero(reservation, l1.labelName) || 0);}, 0)
  }

  public getOtherExtrasValue(
    reservation: any,
    x: ExportDataForExperienceResponse
  ) {
    const otherextras = this.getExtras(x).slice(3);
    return otherextras.reduce((sum,l1)=>{return sum + (this.getExtraOrZero(reservation, l1.labelName) || 0);}, 0)
  }

  public extrasSummaryToObjArr(x: ExportDataForExperienceResponse) {
    const extras = x.summary.extrasTotal;
    const e = Object.keys(extras).map((e) => {
      return { labelName: e, labelValue: extras[e] };
    });
    return e;
  }

  public labelsAndExtrasToObjArr(r: {
    labels: Record<string, number>;
    extras: Record<string, number>;
  }) {
    const labels = r.labels;
    const extras = r.extras;
    const l = Object.keys(labels).map((e) => {
      return { labelName: e, labelValue: labels[e] };
    });
    const e = Object.keys(extras).map((e) => {
      return { labelName: e, labelValue: extras[e] };
    });

    return [...l, ...e];
  }

  getOrigin(origin: any): any {
    if (!!!origin) {
      return "";
    }
    var originFormatted =
      "" +
      origin
        ?.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace(/\:\d+/g, "")
        .toLowerCase();
    if (
      ("" + origin).toLocaleLowerCase().indexOf("manual") > -1 ||
      "Wine Suite".indexOf(origin) > -1
    ) {
      originFormatted = tr("Manual Reservation");
    }
    originFormatted = tr(originFormatted);
    return originFormatted ? originFormatted : origin ? tr(origin) : "";
  }

  roomName(roomId: string): string {
    return this.rooms.find((x) => x.id === roomId)?.name || "Nessuna sala";
  }

  employeeName(employeeId: string): string {
    const ids = employeeId?.split(", ");
    const employees = this.employees.filter(
      (e) => ids.includes(e.id) && (e?.firstName || e?.lastName)
    );
    if (employees.length === 0) return "Nessun Addetto";
    return (
      (employees[0].firstName || "") +
      " " +
      (employees[0].lastName || "") +
      (employees.length > 1 ? " + " + (employees.length - 1) : "")
    );
  }

  public getLabelOrZero(reservation: VwReservation,labelName: string) {
    if (reservation.reservationPriceLabels) {
      for (const label of reservation.reservationPriceLabels) {
        const translatedTitle = Object.values(label.title_translations).find(
          (translation) => translation === labelName
        );
        if (translatedTitle) {
          return label.quantity;
        }
      }
    }
    return 0;
  }

  public getExtraOrZero(reservation: VwReservation, labelName: string) {
    if (reservation.reservationPriceExtras) {
      for (const label of reservation.reservationPriceExtras) {
        const translatedTitle = Object.values(label.title_translations).find(
          (translation) => translation === labelName
        );
        if (translatedTitle) {
          return label.quantity;
        }
      }
    }
    return 0;
  }

  public getStartAndEndDate() {
    if( this.startingDate && this.endingDate){
      let start = moment(this.startingDate).format("DD/MM/YYYY");
      let end = moment(this.endingDate).format("DD/MM/YYYY");

      if(start==end){
        return start;
      }
      return start + " - " + end; 
    }
    return "";
  }

  public formatTime(time: string, format: string = 'HH:mm'): string {
    return moment(time, 'HH:mm:ss').format(format);
  }

  public getStartAndEndTime(){
    let start = moment(this.startingDate).format('HH:mm');
    let end = moment(this.endingDate).format('HH:mm');

    if(start !== '00:00' && end !== '23:59'){
      return start + " - " + end; 
    }
    return "";
  }
}



