import { Injectable } from '@angular/core';
import { resource } from '@util/resource';
import { format } from 'date-fns'
import { Observable } from "rxjs";
import { Moment } from 'moment/moment';
import { VwReservation } from '@interfaces/dws/reservation';

export interface ExportDataExperienceRequest {
  wineryId: string;
  experienceId: string | undefined;
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
}

export interface ExportDataExperienceResponse {
  results: ExportDataForExperienceResponse[];
}

export interface ExportDataForExperienceResponse {
  summary: {
    experienceName: string;
    date: Moment;
    time: string;
    labelsTotal: Record<string, number>,
    extrasTotal: Record<string, number>,
    durationSeconds: number;
    totalParticipants: number;
    maxParticipants: number;
    sumNetTotalCents: number;
    language: string;
  },
  reservations: VwReservation[]
}

@Injectable({
  providedIn: 'root'
})
export class ExportDataForExperienceService {
  public fetch(request: ExportDataExperienceRequest): Observable<ExportDataExperienceResponse> {
    function padZero(number: number) {
      return number < 10 ? '0' + number : number;
    }
    let startDate = new Date(request.startDate);
    startDate = new Date(startDate.getFullYear() + '-' + padZero(startDate.getMonth() + 1) + '-' + padZero(startDate.getDate()) + "T00:00:00.000Z");
    let endDate = new Date(request.endDate);
    endDate = new Date(endDate.getFullYear() + '-' + padZero(endDate.getMonth()+ 1) + '-' + padZero(endDate.getDate()) + "T23:59:59.999Z");

    let startTime = (request.startTime)
    startTime = '00:00'
    let endTime = (request.endTime)
    endTime = '00:00'
    return resource('crm://experiences/export')
      .params({
        wineryId: request.wineryId,
        experienceId: request.experienceId,
        startDate: startDate.toISOString(), endDate: endDate.toISOString()
      })
      .get<ExportDataExperienceResponse>()
  }
}